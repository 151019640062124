<template>
  <div>
    <template>
      <div class="modal-card access-breakdown-modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><i class="mdi mdi-18px mdi-account-group pr-1"></i>{{ name }}</p>
          <button type="button" class="delete" @click="$emit('close')"/>
        </header>
        <section class="access-breakdown-modal-body modal-card-body">
          <div class="content">

            <div class="has-text-centered" v-show="isLoading">
              <LoadingSpinner></LoadingSpinner>
            </div>

            <div class="columns" v-if="readyToShow">
              <div class="column">
                <dl id="modal-lhs-inline-grid">
                  <dt class="dt-key-singleline has-text-left">Domain</dt>
                  <dd class="dt-value-singleline">{{ convertDomainCodeToName("fqdn", this.$props.domain) }}</dd>

                  <dt class="dt-key-singleline has-text-left">Created</dt>
                  <dd class="dt-value-singleline">{{ groupData.dateCreated | vdrPrettyPrintTimestamp}}</dd>

                  <dt class="dt-key-singleline has-text-left" v-if="groupData.description">Description</dt>
                  <dd class="dt-value-singleline" v-if="groupData.description">{{ groupData.description }}</dd>

                  <dt class="dt-key-singleline has-text-left">Cloud sync</dt>
                  <dd class="dt-value-singleline" v-if="this.isCloudSynced">Enabled</dd>
                  <dd class="dt-value-singleline" v-else>
                    <span class="has-text-weight-bold has-text-danger-dark is-uppercase">Disabled</span>
                  </dd>

                  <!--- Only show links to CARMA if:
                        1) The group is cloud synced
                        2) The group name contains "_role_" or "_capability_" or "_sub-"
                   --->
                  <dt class="dt-key-singleline has-text-left"
                      v-if="this.isCloudSynced && groupNameContainsRbacTerminology(name)">Cloud access</dt>
                  <dd class="dt-value-singleline"
                      v-if="this.isCloudSynced && groupNameContainsRbacTerminology(name)">
                    <ul class="ml-0 mt-0">
                      <li class="inline-list">
                        <a :href="`https://carma-nonprod.mandg.co.uk/#/?searchBy=group&query=${encodeURIComponent(name)}`"
                           target="_blank">Nonprod</a>
                      </li>
                      <li class="inline-list">
                        <a :href="`https://carma.mandg.co.uk/#/?searchBy=group&query=${encodeURIComponent(name)}`"
                           target="_blank">Prod</a>
                      </li>
                    </ul>
                  </dd>

                </dl>
              </div>
            </div>

            <div class="columns" v-if="readyToShow">
              <div class="column">
                <div>
                  <p class="has-text-weight-bold"
                     v-bind:class="{ 'pb-0 mb-0': (groupData.members.length >= 1500 && obtainedAllGroupMembers === false) || (showResolveFspHint)}">
                    Members ({{ groupData.members.length }})
                    <span class="tooltip" @mouseleave="leaveTooltip()" v-if="groupData.members.length > 0">
                      <b-tooltip :label="`${this.$data.tooltipText}`" type="is-dark" position="is-right">
                        <i class="mdi mdi-16px mdi-content-copy pl-1"
                           style="cursor: pointer;"
                           @click="copyListToClipboard(allMembers.map(x=>x.displayNameAfterLookup))"></i>
                      </b-tooltip>
                    </span>
                  </p>

                  <p class="is-size-7 is-italic mb-0"
                     v-if="groupData.members.length >= 1500 && obtainedAllGroupMembers === false">Showing first
                    1500 members. <a @click="getAllMembers()">Click here</a> to show all members.
                  </p>

                  <p class="is-size-7 is-italic mb-0" v-show="showResolveFspHint">Some members are from other
                    domains - <a @click="resolveForeignSecurityPrincipals()">click here</a> to show them.
                  </p>

                  <input aria-label="Filter groups" v-model="membersFilter" class="input is-small mb-2"
                         style="width: 350px" type="text" placeholder="Filter members" v-if="groupData.members.length">
                  <div class="memberOfListWrapper mb-1">

                    <div class="has-text-centered" v-show="isLoadingAllMembers">
                      <LoadingSpinner></LoadingSpinner>
                    </div>

                    <ul class="memberOfList">
                      <li v-for="member in filteredMembers" class="is-size-6point5 mt-0">
                        <!-- Group member that is an unresolved FSP (i.e. shows up as a SID) -->
                        <template v-if="member.isForeignSecurityPrincipal && (member.displayName === member.displayNameAfterLookup)">
                          {{member.displayName}}
                        </template>

                        <!-- Group member that is a resolved FSP from PT domain (prupimuat.internal) -->
                        <template v-else-if="member.isForeignSecurityPrincipal && (member.displayName !== member.displayNameAfterLookup) && (member.domain === 'pt')">
                          <span class="has-text-weight-semibold has-text-warning">{{ convertDomainCodeToName("netbios", member.domain) }}\</span>{{member.displayNameAfterLookup}}
                        </template>

                        <!-- Group member that is a resolved FSP from any other domain -->
                        <template v-else-if="member.isForeignSecurityPrincipal && (member.displayName !== member.displayNameAfterLookup)">
                          <a v-bind:href="`#/?domain=${member.domain}&name=${encodeURIComponent(member.displayNameAfterLookup)}`"
                             target="_blank"><span class="has-text-weight-semibold has-text-warning">{{ convertDomainCodeToName("netbios", member.domain) }}\</span>{{member.displayNameAfterLookup}}</a>
                        </template>

                        <!-- Regular group member -->
                        <template v-else>
                          <a v-bind:href="`#/?domain=${member.domain}&name=${encodeURIComponent(member.displayNameAfterLookup)}`"
                             target="_blank">{{member.displayNameAfterLookup}}</a>
                        </template>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="column">
                <div>
                  <p class="has-text-weight-bold">Member of ({{ groupData.memberOf.length }})
                    <span class="tooltip" @mouseleave="leaveTooltip()" v-if="groupData.memberOf.length > 0">
                      <b-tooltip :label="`${this.$data.tooltipText}`" type="is-dark" position="is-right">
                        <i class="mdi mdi-16px mdi-content-copy pl-1"
                           style="cursor: pointer;"
                           @click="copyListToClipboard(groupData.memberOf)"></i>
                      </b-tooltip>
                    </span>
                  </p>
                  <input aria-label="Filter groups" v-model="memberOfFilter" class="input is-small mb-2"
                         style="width: 350px" type="text" placeholder="Filter groups" v-if="groupData.memberOf.length">
                  <div class="memberOfListWrapper">
                    <ul class="memberOfList">
                      <li v-for="adGroup in filteredMemberOf" class="is-size-6point5 mt-0">
                        <a v-bind:href="`#/?domain=${$props.domain}&name=${adGroup}`" target="_blank">{{adGroup}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </template>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/Generic/LoadingSpinner"

export default {
  name: "ModalViewGroup",

  components: {
    LoadingSpinner: LoadingSpinner
  },

  data: function () {
    return {
      isLoading: false,
      isLoadingAllMembers: false,
      readyToShow: false,
      groupData: {
        memberOf: [], // anything where we access .length should be instantiated as an empty array
        members: []
      },
      allMembers: [],
      memberOfFilter: '',
      membersFilter: '',
      obtainedAllGroupMembers: false,
      isCloudSynced: false,
      showResolveFspHint: false,
      tooltipText: 'Copy list to clipboard'
    }
  },

  props: {
    firstName: String,
    lastName: String,
    name: String,
    samAccountName: String,
    objectClass: String,
    domain: String
  },

  mounted: async function () {
    let domain = this.$props.domain
    let formattedDomain = this.vdrConvertDomainCodeToName("netbios", domain);
    let name = this.$props.samAccountName

    this.$appInsights.trackPageView({
      name: "VADER / Home / ModalViewGroup",
      pageType: "Modal",
      isLoggedIn: true,
      properties: { name: `${formattedDomain}\\${name}` }
    })

    let timerThreshold = 900
    let timer = setTimeout(() => {
      console.log(`[ModalViewGroup][${domain} - ${name}] This is taking longer than usual (${timerThreshold}ms). Activating spinner.`)
      this.isLoading = true
    }, timerThreshold)

    let url = this.$settings.apiBaseUri + `View?domain=${domain}&name=${encodeURIComponent(name)}`
    let httpHeaders = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.$authService.vaderToken}`
      }
    };

    let response = await this.$http.get(url, httpHeaders)
    this.groupData = response.data

    let graphUrl = `https://graph.microsoft.com/v1.0/groups?$select=displayName,id&$filter=displayName eq '${encodeURIComponent(this.$props.name)}'`
    let graphHeaders = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.$authService.graphToken}`
      }
    }
    this.isCloudSynced = ((await this.$http.get(graphUrl, graphHeaders)).data.value).length !== 0;

    clearTimeout(timer)
    this.isLoading = false
    this.readyToShow = true

    for (let itemIndex in this.groupData.members) {
      let displayName = this.groupData.members[itemIndex]
      let foreignSecurityPrincipal = this.isForeignSecurityPrincipal(displayName);
      if (!this.showResolveFspHint && foreignSecurityPrincipal) {
        this.showResolveFspHint = true
      }
      let domain = foreignSecurityPrincipal ? "unknown" : this.$props.domain;


      this.allMembers.push({
        isForeignSecurityPrincipal: foreignSecurityPrincipal,
        displayName: displayName,
        displayNameAfterLookup: displayName,
        domain: domain
      })
    }

    console.log(this.groupData)
  },

  methods: {
    convertDomainCodeToName: function(format, domain) {
      return this.vdrConvertDomainCodeToName(format, domain)
    },

    isForeignSecurityPrincipal: function(objectName) {
      return objectName.startsWith('S-1-5-21');
    },

    getAllMembers: async function () {
      this.$appInsights.trackEvent({
        name: "GettingAllMembersOfAdGroup",
        properties: {AdGroup: this.$props.name}
      })

      this.obtainedAllGroupMembers = true
      this.isLoadingAllMembers = true
      this.allMembers = []
      this.membersFilter = ''

      let domain = this.$props.domain
      let name = encodeURIComponent(this.groupData.distinguishedName)

      let url = this.$settings.apiBaseUri + `ViewExpandedGroupMembers?domain=${domain}&distinguishedName=${name}`
      let httpHeaders = {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.$authService.vaderToken}`
        }
      };

      let response = (await this.$http.get(url, httpHeaders)).data

      for (let itemIndex in response) {
        let displayName = response[itemIndex].displayName
        let foreignSecurityPrincipal = this.isForeignSecurityPrincipal(displayName);
        if (!this.showResolveFspHint && foreignSecurityPrincipal) {
          this.showResolveFspHint = true
        }
        let domain = foreignSecurityPrincipal ? "unknown" : this.$props.domain;

        this.allMembers.push({
          isForeignSecurityPrincipal: foreignSecurityPrincipal,
          displayName: displayName,
          displayNameAfterLookup: displayName,
          domain: domain
        })
      }

      this.allMembers = this.allMembers.sort((a,b) => a.displayNameAfterLookup.localeCompare(b.displayNameAfterLookup))
      this.groupData.members = (this.allMembers.map(x=>x.displayName)).sort()
      this.isLoadingAllMembers = false
    },

    resolveForeignSecurityPrincipals: async function () {
      this.$appInsights.trackEvent({
        name: "ResolvingForeignSecurityPrincipalsForAdGroup",
        properties: {AdGroup: this.$props.name}
      })

      this.isLoadingAllMembers = true
      this.showResolveFspHint = false
      let allMembersBackup = this.allMembers
      this.allMembers = []

      let url = this.$settings.apiBaseUri + `SearchForeignSecurityPrincipal`

      let fspDisplayNames = allMembersBackup
          .filter(x=>x.isForeignSecurityPrincipal)
          .map(x=>x.displayName)

      let httpHeaders = {
        headers: {
          'Authorization': `Bearer ${this.$authService.vaderToken}`,
          'Content-Type': 'application/json'
        },
      }
      
      let resolvedFsps = (await this.$http.post(url, fspDisplayNames, httpHeaders))
          .data
          .objectSidLookup.filter(item => item.details != null)

      for (let index in resolvedFsps) {
        let sid = resolvedFsps[index].objectSid
        let details = resolvedFsps[index].details
        let fspFromAllMembersArray = allMembersBackup.find(x=>x.displayName === sid)
        fspFromAllMembersArray.displayNameAfterLookup = details.name
        fspFromAllMembersArray.domain = this.vdrInferDomainFromDistinguishedName(details.distinguishedName)
      }
      this.isLoadingAllMembers = false
      this.allMembers = allMembersBackup.sort((a,b) => a.displayNameAfterLookup.localeCompare(b.displayNameAfterLookup))
    },

    groupNameContainsRbacTerminology: function(groupName) {
      groupName = groupName.toLowerCase()
      return (groupName.includes("_role_") || groupName.includes("_capability_") || groupName.includes("_sub-"));
    },

    copyListToClipboard: function(list) {
      this.tooltipText = "Copied"

      let clipboardContent = ""
      list.forEach(listItem => {
        clipboardContent += listItem + "\n"
      })

      navigator.clipboard.writeText(clipboardContent);
    },

    leaveTooltip: function () {
      // Set a small delay to create a more fluid transition between old text and new text
      setTimeout(() => {  this.tooltipText = "Copy list to clipboard" }, 300);
    }
  },

  computed: {
    filteredMembers() {
      return this.allMembers.filter(item => {
        return item.displayNameAfterLookup.toLowerCase().indexOf(this.membersFilter.toLowerCase()) > -1
      })
    },

    filteredMemberOf() {
      return this.groupData.memberOf.filter(item => {
        return item.toLowerCase().indexOf(this.memberOfFilter.toLowerCase()) > -1
      })
    }
  },

}
</script>