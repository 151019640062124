<template>
  <div>
    <h1 class="is-size-3">Results</h1>

    <div class="results-users pb-4" v-if="users.length > 0">
      <h2 class="is-size-4">Users ({{ users.length }})</h2>
      <table class="table is-fullwidth no-padding no-borders">
        <thead>
          <tr>
            <th class="pb-1 pr-3">Name</th>
            <th class="pb-1 pr-3">User ID</th>
            <th class="pb-1">Job Title & Department</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, i) in users" :key="i">
            <td class="pr-3">
              <a @click="showViewEntryModal(user)">{{ user.firstName }} {{ user.lastName }}</a>
            </td>
            <td class="pr-3">
              <a @click="showViewEntryModal(user)">{{ user.samAccountName }}</a>
            </td>
            <td>
              <a @click="showViewEntryModal(user)" v-if="DeptOrJobTitleExists(user)">{{ user | showDeptAndJobTitle}}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="results-groups pb-4" v-if="groups.length > 0">
      <h2 class="is-size-4">Groups ({{ groups.length }})</h2>
      <ul v-for="group in groups">
        <li>
          <a @click="showViewEntryModal(group)">{{ group.name }}</a>
        </li>
      </ul>
    </div>

    <div class="results-computers pb-4" v-if="computers.length > 0">
      <h2 class="is-size-4">Computers ({{ computers.length }})</h2>
      <ul v-for="computer in computers">
        <li>
          <a @click="showViewEntryModal(computer)">{{ computer.name }}</a>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
import ModalViewUser from "@/components/ViewEntry/ModalViewUser";
import ModalViewGroup from "@/components/ViewEntry/ModalViewGroup";
import ModalViewComputer from "@/components/ViewEntry/ModalViewComputer";

export default {
  name: "SearchResults",

  data: function () {
    return {}
  },

  props: {
    autocompleteResults: Array,
    searchDomain: String
  },

  mounted() {
  },

  methods: {
    showViewEntryModal: function(record) {
      let modalName;
      if (record.objectClass === 'user') {
        modalName = ModalViewUser
      } else if (record.objectClass === 'group') {
        modalName = ModalViewGroup
      } else {
        modalName = ModalViewComputer
      }

      let properties = {
        firstName: record.firstName,
        lastName: record.lastName,
        name: record.name,
        samAccountName: record.samAccountName,
        objectClass: record.objectClass,
        domain: this.$props.searchDomain
      };

      this.$buefy.modal.open({
        parent: this,
        component: modalName,
        trapFocus: true,
        hasModalCard: true,
        props: properties
      })
    },

    DeptOrJobTitleExists: function(userDetails) {
      return (userDetails.department || userDetails.jobTitle);
    }
  },

  computed: {
    users: function() {
      if (!this.$props.autocompleteResults)
        return []

      return this.$props.autocompleteResults.filter((x => x.objectClass === "user"))
    },

    groups: function() {
      if (!this.$props.autocompleteResults)
        return []

      return this.$props.autocompleteResults.filter((x => x.objectClass === "group"))
    },

    computers: function() {
      if (!this.$props.autocompleteResults)
        return []

      return this.$props.autocompleteResults.filter((x => x.objectClass === "computer"))
    }
  },

  filters: {
    showDeptAndJobTitle: function(userDetails) {
      if (userDetails.department && userDetails.jobTitle) {
        return `${userDetails.jobTitle}, ${userDetails.department}`
      }
      else if (userDetails.department) {
        return `${userDetails.department}`
      }
      else if (userDetails.jobTitle) {
        return `${userDetails.jobTitle}`
      }
    }
  }

}
</script>

<style scoped>
</style>