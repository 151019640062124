<template>
  <div class="home">
    <section id="searchbar" class="mt-6">
      <h1 class="is-size-3 has-text-centered">
        <span>Enter search criteria here</span>
        <b-tooltip class="pl-1" position="is-right" type="is-dark" multilined>
          <template v-slot:content>
            <p class="has-text-left pb-2">Use an asterisk before/after your search criteria for wildcards searches (e.g.
              <code>*JIRA*</code>).<br>Note - using wildcards can slow down search performance.</p>
            <p class="has-text-left pt-2">You can also compare group memberships of two users by entering two user IDs,
              separated by an equals sign (e.g. <code>UserId1=UserId2</code>)</p>
          </template>
          <span class="icon"><i class="mdi mdi-24px mdi-information has-text-info"></i></span>
        </b-tooltip>
      </h1>

      <div class="container is-max-desktop is-centered">
        <div class="field has-addons mt-6">
          <p class="control">
              <span class="select">
                <select aria-label="domain" v-model="searchDomain" @change="resetSearchInput">
                  <option value="mg">mandg.co.uk</option>
                  <option value="pr">production.local</option>
                  <option value="pg">pgds.local</option>
                  <option value="cp">corp.local</option>
                  <option value="ct">corptest.local</option>
                </select>
              </span>
          </p>
          <p class="control is-expanded">
            <input class="input" type="text" placeholder="Type in a username, AD group or computer name here"
                   aria-label="Search" ref="searchInput" v-model="searchQuery"
                   v-debounce:300ms="processKeyUpEvent"
                   v-on:keyup="resetComparisonComponentIfRequired"
                   v-on:keyup.enter="callSearchApi(true)">
          </p>
        </div>
      </div>

      <div class="container is-max-desktop has-text-centered mb-5 mt-5">
        <template>
          <b-field>
            <b-button @click="callSearchApi(true)">Search</b-button>
          </b-field>
        </template>
      </div>

      <div class="has-text-centered" v-show="isLoading">
        <LoadingSpinner></LoadingSpinner>
      </div>

      <div class="container is-max-desktop" v-if="noResultsMessage === true">
        <h1 class="is-size-4">No results found</h1>
      </div>

      <div class="container is-max-desktop" v-else-if="resultsFound()">
        <SearchResults v-bind:autocompleteResults="autocompleteResults"
                       v-bind:searchDomain="searchDomain">
        </SearchResults>
      </div>

      <div class="container is-max-widescreen mt-6 mb-2" v-if="this.comparisonResults.length !== 0">
        <CompareEntries v-bind:comparisonData="comparisonResults"
                        v-bind:domain="searchDomain">
        </CompareEntries>
      </div>

    </section>
  </div>
</template>

<script>
import ModalViewUser from "@/components/ViewEntry/ModalViewUser"
import ModalViewGroup from "@/components/ViewEntry/ModalViewGroup"
import ModalViewComputer from "@/components/ViewEntry/ModalViewComputer"
import SearchResults from "@/components/SearchResults/SearchResults"
import CompareEntries from "@/components/CompareEntries/CompareEntries"
import LoadingSpinner from "@/components/Generic/LoadingSpinner"

export default {
  name: "Home",

  components: {
    ModalViewUser: ModalViewUser,
    ModalViewGroup: ModalViewGroup,
    ModalViewComputer: ModalViewComputer,
    SearchResults: SearchResults,
    CompareEntries: CompareEntries,
    LoadingSpinner: LoadingSpinner
  },

  watch: {
    async '$route.query'() {
      this.$router.go()
      await this.detectValidQueryStrings()
    }
  },

  async mounted() {
    this.$refs.searchInput.focus()
    await this.detectValidQueryStrings()

    // Assuming no query strings, look at the user's access token to find their
    // on-premises SID value and use that to infer their source AD domain
    // Use that to determine what the 'default' domain should be in the dropdown menu
    let accessToken = JSON.parse(atob(this.$authService.tokenResponse.accessToken.split(".")[1]));
    let sid = accessToken.onprem_sid.toUpperCase()

    let userOnPremSourceDomain;
    if (sid.startsWith("S-1-5-21-2024811613-2105829882-1706709998")) {
      userOnPremSourceDomain = "mg"
    } else if (sid.startsWith("S-1-5-21-154607854-2034762490-1526957132")) {
      userOnPremSourceDomain = "cp"
    } else if (sid.startsWith("S-1-5-21-687370610-809116199-1266042623")) {
      userOnPremSourceDomain = "pg"
    } else if (sid.startsWith("S-1-5-21-1967852569-226447694-1543859470")) {
      userOnPremSourceDomain = "pr"
    } else {
      userOnPremSourceDomain = "cp"
    }
    this.searchDomain = userOnPremSourceDomain
  },

  data: function () {
    return {
      searchDomain: "mg",
      searchQuery: null,
      latestSearchQuery: null, // use this variable to avoid wrongly ordered async responses
      ignoreLateArrivingSearchResults: false,
      autocompleteResults: [],
      searchQueryWhichTriggeredComparison: null,
      comparisonResults: [],
      isFetchingAutocompleteResults: false,
      isSearchBarDirty: false,
      isLoading: false,
      noResultsMessage: false
    };
  },

  methods: {
    resetSearchInput: function() {
      this.searchQuery = null
      this.latestSearchQuery = null
      this.autocompleteResults = []
      this.comparisonResults = []
      this.searchQueryWhichTriggeredComparison = null
      this.searchQueryWhichTriggeredPreviousSearch = null
      this.isFetchingAutocompleteResults = false
      this.isSearchBarDirty = false
      this.isLoading = false
      this.noResultsMessage = false
    },

    resetQueryStrings: function(){
      this.$router.push(this.$route.path)
    },

    detectValidQueryStrings: async function() {
      let domain = ['mg', 'pr', 'pg', 'cp', 'ct', 'pt'].includes(this.$route.query.domain)
          ? this.$route.query.domain
          : null;

      let name = this.$route.query.name !== undefined && (this.$route.query.name).trim()
          ? decodeURI((this.$route.query.name).trim())
          : null;

      if (!(name && domain)) {
        console.log("Invalid name and/or domain. Query string in URL will be ignored");
        return
      }

      this.searchDomain =  domain

      this.isLoading = true
      let url = this.$settings.apiBaseUri + `Search?domain=${domain}&name=${encodeURIComponent(name)}`
      let httpHeaders = {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.$authService.vaderToken}`
        }
      };
      let response = await this.$http.get(url, httpHeaders)
      let searchResults = response.data;
      this.isLoading = false

      if (searchResults.length === 0) {
        console.log("Invalid name and/or domain. Query string in URL will be ignored");
        this.$router.push(this.$route.path)
        return
      }

      this.autocompleteResults = []
      searchResults.forEach((record) => this.autocompleteResults.push(record))
      this.isFetchingAutocompleteResults = false
      this.searchQuery = name

      if (searchResults.length === 1) {
        let modalName;
        if (searchResults[0].objectClass === 'user') {
          modalName = ModalViewUser
        } else if (searchResults[0].objectClass === 'group') {
          modalName = ModalViewGroup
        } else {
          modalName = ModalViewComputer
        }

        let properties = {
          firstName: searchResults[0].firstName,
          lastName: searchResults[0].lastName,
          name: searchResults[0].name,
          samAccountName: searchResults[0].samAccountName,
          objectClass: searchResults[0].objectClass,
          domain: domain
        };

        const modal = this.$buefy.modal.open({
          parent: this,
          component: modalName,
          trapFocus: true,
          hasModalCard: true,
          props: properties,
        })

        modal.$on('close', this.resetQueryStrings)
      }
    },

    resultsFound: function () {
      return (this.autocompleteResults.length > 0)
          && (this.searchQuery != null)
    },

    processKeyUpEvent: async function () {
      if (this.searchQuery !== null && this.searchQuery.length && this.searchQuery.includes("=")) {
        this.isSearchBarDirty = false
        this.autocompleteResults = []
        this.isFetchingAutocompleteResults = false
        this.noResultsMessage = false
        return false
      }

      await this.callSearchApi(false)
    },

    resetComparisonComponentIfRequired: function() {
      if (this.comparisonResults != null &&
        this.searchQuery !== null &&
        this.searchQuery.trim() !== this.searchQueryWhichTriggeredComparison) {
        this.comparisonResults = []
        this.searchQueryWhichTriggeredComparison = null
      }
    },

    callSearchApi: async function (enterPressed) {
      let trimmedSearchQuery = this.searchQuery !== null ? this.searchQuery.trim() : null;
      if (trimmedSearchQuery === null) return

      // Don't do anything if user is searching for the same thing as they're currently looking at
      if (this.searchQueryWhichTriggeredComparison === trimmedSearchQuery ||
          this.searchQueryWhichTriggeredPreviousSearch === trimmedSearchQuery
      ) {
        return
      }

      this.noResultsMessage = false
      this.latestSearchQuery = trimmedSearchQuery
      this.isFetchingAutocompleteResults = true
      this.isSearchBarDirty = true;

      let url = this.$settings.apiBaseUri + `Search?domain=${this.searchDomain}&name=${encodeURIComponent(trimmedSearchQuery)}`
      let httpHeaders = {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.$authService.vaderToken}`
        }
      };

      if (trimmedSearchQuery.includes("=") && enterPressed) {
        this.isLoading = true

        let name1 = trimmedSearchQuery.split("=")[0].trim()
        let name2 = trimmedSearchQuery.split("=")[1].trim()
        url = this.$settings.apiBaseUri + `Compare?domain=${this.searchDomain}&name1=${name1}&name2=${name2}`
        let response = await this.$http.get(url, httpHeaders)
        this.comparisonResults = response.data
        this.searchQueryWhichTriggeredComparison = trimmedSearchQuery
        this.isLoading = false
        return
      }

      // If input is less than 2, do nothing, but submit a fake API call to wake up the API backend
      if (this.searchQuery === null || this.searchQuery.trim().length === 0 || this.searchQuery.trim().length < 2) {
        this.autocompleteResults = []
        this.isFetchingAutocompleteResults = true
        url = this.$settings.apiBaseUri + `Search?domain=${this.searchDomain}&name=wakeup_dummy_call`
        await this.$http.get(url, httpHeaders)
        return true
      }

      let timerThreshold = 900
      let timer = setTimeout(() => {
        console.log(`This is taking longer than usual (${timerThreshold}ms). Activating spinner.`)
        this.isLoading = true
      }, timerThreshold)

      let response = await this.$http.get(url, httpHeaders)

      this.searchQueryWhichTriggeredPreviousSearch = trimmedSearchQuery
      clearTimeout(timer)

      if (this.ignoreLateArrivingSearchResults) {
        console.log("Rejecting late arriving search response")
        this.ignoreLateArrivingSearchResults = false
      } else if (response.status !== 200) {
        this.autocompleteResults = []
        this.isLoading = false
        console.error(`[HOME][PostSearch] Failed to get autocomplete data, API returned HTTP ${httpResponseCode}`)
      } else {
        if (this.latestSearchQuery === trimmedSearchQuery) {
          this.autocompleteResults = []
          this.isFetchingAutocompleteResults = false
          this.isLoading = false

          if (response.data.length === 0) {
            this.noResultsMessage = true
            this.isLoading = false
            return
          }

          response.data.forEach((record) => this.autocompleteResults.push(record))
        }
      }
    }
  }
};
</script>

<style>
</style>
