<template>
  <div>
    <template>
      <div class="modal-card access-breakdown-modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><i class="mdi mdi-18px mdi-monitor pr-1"></i>{{ samAccountName | formattedMachineName }}</p>
          <button type="button" class="delete" @click="$emit('close')"/>
        </header>
        <section class="access-breakdown-modal-body modal-card-body">
          <div class="content">

            <div class="has-text-centered" v-show="isLoading">
              <LoadingSpinner></LoadingSpinner>
            </div>

            <div class="columns" v-if="readyToShow">
              <div class="column">
                <dl id="modal-lhs-grid">
                  <dt class="dt-key-singleline">Domain</dt>
                  <dd class="dt-value-singleline">{{ convertDomainCodeToName("fqdn", this.$props.domain) }}</dd>

                  <dt class="dt-key-singleline">OU path</dt>
                  <dd class="dt-value-singleline">{{userData.distinguishedName | getOuPath}}</dd>

                  <dt class="dt-key-singleline">DNS hostname</dt>
                  <dd class="dt-value-singleline">{{userData.dnsHostName}}</dd>

                  <dt class="dt-key-singleline">OS</dt>
                  <dd class="dt-value-singleline">{{userData.operatingSystem}}</dd>

                  <dt class="dt-key-singleline">OS version</dt>
                  <dd class="dt-value-singleline">{{userData.operatingSystemVersion}}</dd>

                  <dt class="dt-key-singleline">Creation</dt>
                  <dd class="dt-value-singleline">{{userData.dateCreated | vdrPrettyPrintTimestamp}}</dd>

                  <dt class="dt-key-singleline">Last logon</dt>
                  <dd class="dt-value-singleline">{{userData.lastLogon | vdrPrettyPrintTimestamp}}</dd>
                </dl>
              </div>

              <div class="column">
                <div v-if="userData.memberOf.length">
                  <p class="has-text-weight-bold">Machine AD groups ({{ userData.memberOf.length }})</p>
                  <input aria-label="Filter groups" v-model="adGroupFilter" class="input is-small mb-2" style="width: 350px" type="text" placeholder="Filter groups">
                  <div class="scrollable">
                    <ul class="memberOfList">
                      <li v-for="adGroup in filteredAdGroups" class="is-size-6point5 mt-0">
                        <a v-bind:href="`#/?domain=${$props.domain}&name=${encodeURIComponent(adGroup)}`" target="_blank">{{adGroup}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </template>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/Generic/LoadingSpinner"

export default {
  name: "ModalViewComputer",

  components: {
    LoadingSpinner: LoadingSpinner
  },

  data: function () {
    return {
      isLoading: false,
      readyToShow: false,
      userData: {
        memberOf: [] // anything where we access .length should be instantiated as an empty array
      },
      adGroupFilter: ''
    }
  },

  props: {
    firstName: String,
    lastName: String,
    name: String,
    samAccountName: String,
    objectClass: String,
    domain: String
  },

  async mounted() {
    let domain = this.$props.domain
    let formattedDomain = this.vdrConvertDomainCodeToName("netbios", domain);
    let name = this.$props.samAccountName

    this.$appInsights.trackPageView({
      name: "VADER / Home / ModalViewComputer",
      pageType: "Modal",
      isLoggedIn: true,
      properties: { name: `${formattedDomain}\\${name}` }
    })

    let timerThreshold = 900
    let timer = setTimeout(() => {
      console.log(`[ModalViewComputer][${domain} - ${name}] This is taking longer than usual (${timerThreshold}ms). Activating spinner.`)
      this.isLoading = true
    }, timerThreshold)

    let url = this.$settings.apiBaseUri + `View?domain=${domain}&name=${encodeURIComponent(name)}`
    let httpHeaders = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.$authService.vaderToken}`
      }
    };

    let response = await this.$http.get(url, httpHeaders)
    clearTimeout(timer)
    this.isLoading = false
    this.readyToShow = true
    this.userData = response.data
  },

  methods: {
    convertDomainCodeToName: function(format, domain) {
      return this.vdrConvertDomainCodeToName(format, domain)
    },
  },

  computed: {
    filteredAdGroups() {
      return this.userData.memberOf.filter(item => {
        return item.toLowerCase().indexOf(this.adGroupFilter.toLowerCase()) > -1
      })
    }
  },

  filters: {
    formattedMachineName(value) {
      return value.toLowerCase().replace('$', '')
    },

    getOuPath(distinguishedName) {
      let segments = distinguishedName.split(',');

      let domainSegments = segments.filter((segment) => segment.startsWith("DC="));
      let domain = domainSegments.join(".").replaceAll("DC=", "");

      let ouPathSegments = segments.filter((segment) => segment.startsWith("OU=")).reverse();
      let ouPath = ouPathSegments.join("/").replaceAll("OU=", "")

      return ouPath;
      // return domain + '/' + ouPath;
    }
  }
}
</script>