import * as Msal from '@azure/msal-browser';

const msalConfig = {
    auth: {
        clientId: null,                 // client ID of the VADER UI service principal
        authority: null,                // tenant ID
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
    }
};

export default class AuthenticationService {

    isLoggedIn = false
    isAuthenticatedButNotAuthorised = false
    tokenResponse = null
    vaderToken = null
    graphToken = null
    webAppRoles = []
    settingsCollection = null

    constructor(settings) {
        msalConfig.auth = {
            clientId: `${settings.vaderWebApplicationId}`,
            authority: `https://login.microsoftonline.com/${settings.tenantId}/`,
            redirectUri: `${settings.msalRedirectUri}`
        }

        this.settingsCollection = settings
        this.app = new Msal.PublicClientApplication(msalConfig)
    }

    init = async () => {

        try {
            let tokenResponse = await this.app.handleRedirectPromise()
            let accountObj

            if (tokenResponse) {
                accountObj = tokenResponse.account;
            } else {
                accountObj = this.app.getAllAccounts()[0];
            }

            // When requesting a token from AAD, the scope is derived from the CLIENT ID of the Function App SP
            // DO NOT use the function app URL (i.e. https://functionappname.azurewebsites.net)
            let vaderScope = `${this.settingsCollection.vaderServiceApplicationId}/user_impersonation`

            if (accountObj && tokenResponse) {
                await this.setStateToLoggedIn(tokenResponse)
            } else if (accountObj) {
                try {
                    tokenResponse = await this.app.acquireTokenSilent({
                        account: this.app.getAllAccounts()[0],
                        scopes: [vaderScope]
                    })
                    await this.setStateToLoggedIn(tokenResponse)
                } catch (err) {
                    await this.app.acquireTokenRedirect({scopes: [vaderScope]});
                }
            } else {
                await this.app.loginRedirect()
            }
        } catch (error) {
            if (error.toString().toLocaleUpperCase().includes("AADSTS50105")) {
                console.error("You are not assigned a role for this application.", error.toString())
                this.setStateToUnauthorised()
            } else {
                console.error("[AuthService.init] Failed to handleRedirectPromise()", error)
                window.location.reload()
            }
        }

    }

    async getTokensForOtherResources(request) {
        try {
            return await this.app.acquireTokenSilent(request)
        } catch (err) {
            console.error("[getTokensForOtherResources] Error whilst acquireTokenSilent for", request)
            console.error("[getTokensForOtherResources] Attempting to get token via acquireTokenRedirect")
            return await this.app.acquireTokenRedirect(request)
        }
    }

    async setStateToLoggedIn(tokenResponse) {
        let requestForGraphToken = {
            account: this.app.getAllAccounts()[0],
            scopes: ["user.read.all", "group.read.all"]
        };

        this.isLoggedIn = true
        this.isAuthenticatedButNotAuthorised = false
        this.tokenResponse = tokenResponse
        this.vaderToken = tokenResponse.accessToken
        this.graphToken = (await this.getTokensForOtherResources(requestForGraphToken)).accessToken
        this.webAppRoles = tokenResponse.idTokenClaims.roles

        if (process.env.NODE_ENV === "development") {
            console.log("Token response is obtained", tokenResponse,)
        }
    }

    setStateToUnauthorised() {
        this.isLoggedIn = false
        this.isAuthenticatedButNotAuthorised = true
    }

    requestNewToken = async () => {
        await this.init()
    }

    logout = async () => {
        this.isLoggedIn = false
        await this.app.logout({
            account: this.app.getAllAccounts()[0],
            postLogoutRedirectUri: "https://www.mandg.co.uk"
        });
    }

}
