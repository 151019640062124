<template>
  <div>
    <div class="has-text-centered">
      <!-- https://loading.io/css/ -->
      <div class="vader-loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner"
}
</script>

<style scoped>
.vader-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.vader-loader div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #7ebd5f;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.vader-loader div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.vader-loader div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.vader-loader div:nth-child(3) {
  left: 56px;
  animation-delay: 0s;
}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
</style>