<template>
  <div id="app">

    <div id="app-unauthorised" v-if="this.$authService.isAuthenticatedButNotAuthorised === true">
      <div class="pre-footer-wrapper">
        <header class="mb-6">
          <template>
            <b-navbar>
              <template slot="brand">
                <b-navbar-item tag="router-link" :to="{ path: '/' }"
                               class="is-size-4"
                               title="View Active Directory Entries (v20210813.1)">
                  VADER
                </b-navbar-item>
              </template>

              <template slot="end">
                <b-navbar-item v-on:click="initiateLogout()">
                  Sign out
                </b-navbar-item>
              </template>
            </b-navbar>
          </template>
        </header>
        <div class="container">
          <comp-unauthorised></comp-unauthorised>
        </div>
      </div>
      <app-footer></app-footer>
    </div>

    <div id="app-logged-in" v-else-if="this.$authService.isLoggedIn === true">
      <div class="pre-footer-wrapper">
        <header class="mb-6">
          <template>
            <b-navbar>
              <template slot="brand">
                <b-navbar-item tag="router-link" :to="{ path: '/' }"
                               class="is-size-4"
                               title="View Active Directory Entries (v20210813.1)">
                  VADER
                </b-navbar-item>
              </template>

              <template slot="end">
                <b-navbar-item tag="router-link" :to="{ path: 'help' }">
                  Help
                </b-navbar-item>
                <b-navbar-item v-if="isSupportedDomain()" v-on:click="viewMyself()">
                  View myself
                </b-navbar-item>
                <b-navbar-item v-on:click="initiateLogout()">
                  Sign out
                </b-navbar-item>
              </template>
            </b-navbar>
          </template>
        </header>
        <div class="container">
          <router-view/>
        </div>
      </div>
      <app-footer></app-footer>
    </div>

    <div v-else>
      <b-loading :is-full-page="true" :can-cancel="false" :active="true"></b-loading>
    </div>

  </div>
</template>

<script>
import Footer from '@/components/Generic/Footer'
import Unauthorised from '@/components/Generic/Unauthorised'

export default {

  components: {
    'comp-unauthorised': Unauthorised,
    'app-footer': Footer
  },

  mounted: function() {},

  methods: {
    initiateLogout: function() {
      this.$authService.logout();
    },

    isSupportedDomain: function() {
      let accessToken = JSON.parse(atob(this.$authService.tokenResponse.accessToken.split(".")[1]));
      let userSid = accessToken.onprem_sid.toUpperCase()

      let supportedDomainSids = [
          "S-1-5-21-2024811613-2105829882-1706709998", // MG
          "S-1-5-21-154607854-2034762490-1526957132",  // CP
          "S-1-5-21-687370610-809116199-1266042623",   // PG
          "S-1-5-21-1967852569-226447694-1543859470",  // PR
          "S-1-5-21-3764459120-1324268939-2274317694", // CT
          "S-1-5-21-2093405405-3173940693-2470778517"  // PT
      ]

      return supportedDomainSids.some(domainSid=>userSid.includes(domainSid))
    },

    viewMyself: async function() {
      let accessToken = JSON.parse(atob(this.$authService.tokenResponse.accessToken.split(".")[1]));
      let upn = this.$authService.tokenResponse.account.username
      let sid = accessToken.onprem_sid.toUpperCase()

      let domain;
      if (sid.startsWith("S-1-5-21-2024811613-2105829882-1706709998")) {
        domain = "mg"
      } else if (sid.startsWith("S-1-5-21-154607854-2034762490-1526957132")) {
        domain = "cp"
      } else if (sid.startsWith("S-1-5-21-687370610-809116199-1266042623")) {
        domain = "pg"
      } else if (sid.startsWith("S-1-5-21-1967852569-226447694-1543859470")) {
        domain = "pr"
      } else if (sid.startsWith("S-1-5-21-3764459120-1324268939-2274317694")) {
        domain = "ct"
      } else if (sid.startsWith("S-1-5-21-2093405405-3173940693-2470778517")) {
        domain = "pt"
      }

      await this.$router.push(`/?domain=${domain}&name=${upn}`)
    }
  },

  filters: {}
}
</script>

<style lang="scss">
</style>
