import vdrPrettyPrintTimestamp from "@/helpers/vdrPrettyPrintTimestamp";
import vdrInferDomainFromDistinguishedName from "@/helpers/vdrInferDomainFromDistinguishedName"
import vdrConvertDomainCodeToName from "@/helpers/vdrConvertDomainCodeToName";

export default {
    install(Vue) {
        Vue.filter('vdrPrettyPrintTimestamp', vdrPrettyPrintTimestamp);
        Vue.prototype.vdrInferDomainFromDistinguishedName = vdrInferDomainFromDistinguishedName;
        Vue.prototype.vdrConvertDomainCodeToName = vdrConvertDomainCodeToName;
    }
}