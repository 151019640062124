<template>
  <div>
    <div class="columns" style="background-color: #7ebd5f; padding: 5px; color: white;">
      <h1 class="is-size-4"><i class="mdi mdi-24px mdi-account-switch pr-1"></i>AD group comparison
        between {{ getFullNameIfPresent("user1") }} and {{ getFullNameIfPresent("user2") }}</h1>
    </div>

    <div class="columns has-text-centered">
      <div class="column is-half is-offset-one-quarter">
        <input aria-label="Filter groups" v-model="adGroupFilter" class="input is-small mb-2"
               style="width: 350px" type="text" placeholder="Filter groups">
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <p class="has-text-weight-bold">
          Groups for {{ getFullNameIfPresent("user1") }} only ({{comparisonData.groupsForUser1Only.length}})
        </p>
        <div class="memberOfListWrapper">
          <ul class="memberOfList">
            <li v-for="adGroup in filteredGroupsForUser1Only" class="is-size-7 mt-0">
              <template v-if="isForeignSecurityPrincipal(adGroup)">
                {{adGroup}}
              </template>
              <template v-else>
                <a v-bind:href="`#/?domain=${$props.domain}&name=${encodeURIComponent(adGroup)}`" target="_blank">{{adGroup}}</a>
              </template>
            </li>
          </ul>
        </div>
      </div>

      <div class="column">
        <p class="has-text-weight-bold">
          Groups for both users ({{comparisonData.groupsForBothUsers.length}})
        </p>
        <div class="memberOfListWrapper">
          <ul class="memberOfList">
            <li v-for="adGroup in filteredGroupsForBothUsers" class="is-size-7 mt-0">
              <template v-if="isForeignSecurityPrincipal(adGroup)">
                {{adGroup}}
              </template>
              <template v-else>
                <a v-bind:href="`#/?domain=${$props.domain}&name=${encodeURIComponent(adGroup)}`" target="_blank">{{adGroup}}</a>
              </template>
            </li>
          </ul>
        </div>
      </div>

      <div class="column">
        <p class="has-text-weight-bold">
          Groups for {{ getFullNameIfPresent("user2") }} only ({{comparisonData.groupsForUser2Only.length}})
        </p>
        <div class="memberOfListWrapper">
          <ul class="memberOfList">
            <li v-for="adGroup in filteredGroupsForUser2Only" class="is-size-7 mt-0">
              <template v-if="isForeignSecurityPrincipal(adGroup)">
                {{adGroup}}
              </template>
              <template v-else>
                <a v-bind:href="`#/?domain=${$props.domain}&name=${encodeURIComponent(adGroup)}`" target="_blank">{{adGroup}}</a>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "CompareObjects",

  data: function () {
    return {
      adGroupFilter: ''
    }
  },

  props: {
    comparisonData: Object,
    domain: String
  },

  async mounted() {
    let domain = this.$props.domain
    let formattedDomain = this.vdrConvertDomainCodeToName("netbios", domain);
    let name1 = this.$props.comparisonData.user1Id
    let name2 = this.$props.comparisonData.user2Id
    let comparison = `${formattedDomain}\\${name1} & ${formattedDomain}\\${name2}`;

    this.$appInsights.trackPageView({
      name: "VADER / Home / CompareEntries",
      pageType: "Component",
      isLoggedIn: true,
      properties: { name: comparison }
    })
  },

  methods: {
    isForeignSecurityPrincipal: function(objectName) {
      return objectName.startsWith('S-1-5-21');
    },
    getFullNameIfPresent: function(user) {
      let firstAndLastName
      let firstName
      let surname
      let userId

      if (user === "user1") {
        firstName = this.$props.comparisonData.user1FirstName === null ? "" : this.$props.comparisonData.user1FirstName;
        surname = this.$props.comparisonData.user1Surname === null ? "" : this.$props.comparisonData.user1Surname;
        userId = this.$props.comparisonData.user1Id
      }

      if (user === "user2") {
        firstName = this.$props.comparisonData.user2FirstName === null ? "" : this.$props.comparisonData.user2FirstName;
        surname = this.$props.comparisonData.user2Surname === null ? "" : this.$props.comparisonData.user2Surname;
        userId = this.$props.comparisonData.user2Id
      }

      firstAndLastName = (`${(firstName)} ${(surname)}`).trim()
      if ( (firstAndLastName.trim() === "") || (firstName === surname)) {
        return userId
      }

      if (firstAndLastName === userId) {
        return userId
      }

      return `${firstAndLastName} (${userId})`
    }
  },

  computed: {
    filteredGroupsForUser1Only() {
      return this.$props.comparisonData.groupsForUser1Only.filter(item => {
        return item.toLowerCase().indexOf(this.adGroupFilter.toLowerCase()) > -1
      })
    },

    filteredGroupsForBothUsers() {
      return this.$props.comparisonData.groupsForBothUsers.filter(item => {
        return item.toLowerCase().indexOf(this.adGroupFilter.toLowerCase()) > -1
      })
    },

    filteredGroupsForUser2Only() {
      return this.$props.comparisonData.groupsForUser2Only.filter(item => {
        return item.toLowerCase().indexOf(this.adGroupFilter.toLowerCase()) > -1
      })
    }
  }

}
</script>

<style scoped>

</style>