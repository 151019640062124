export default function vdrInferDomainFromDistinguishedName(distinguishedName) {
    distinguishedName = distinguishedName.toLowerCase()

    if (distinguishedName.endsWith("dc=production,dc=local")) {
        return "pr"
    }

    else if (distinguishedName.endsWith("dc=corp,dc=local")) {
        return "cp"
    }

    else if (distinguishedName.endsWith("dc=corptest,dc=local")) {
        return "ct"
    }

    else if (distinguishedName.endsWith("dc=prupimuat,dc=internal")) {
        return "pt"
    }

    else if (distinguishedName.endsWith("dc=pgds,dc=local")) {
        return "pg"
    }

    else if (distinguishedName.endsWith("dc=mandg,dc=co,dc=uk")) {
        return "mg"
    }

    else {
        return "unknown"
    }
}