<template>
  <div>
    <template>
      <div class="modal-card access-breakdown-modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="firstName && lastName">
            <i class="mdi mdi-18px mdi-account pr-1"></i>{{ firstName }} {{ lastName }}
          </p>
          <p class="modal-card-title" v-else>
            <i class="mdi mdi-18px mdi-account pr-1"></i>{{ samAccountName }}
          </p>
          <button type="button" class="delete" @click="$emit('close')"/>
        </header>
        <section class="access-breakdown-modal-body modal-card-body">
          <div class="content">

            <div class="has-text-centered" v-show="isLoading">
              <LoadingSpinner></LoadingSpinner>
            </div>

            <div class="columns" v-if="readyToShow">

              <div class="column is-half">

                <div class="photoContainer has-text-centered" v-if="this.photo">
                  <img style="max-height: 170px;"
                       :src="`data:image/jpeg;base64,${this.photo}`"
                       :alt="`Photo of ${this.$props.firstName} ${this.$props.lastName}`">
                </div>

                <dl id="modal-lhs-grid">
                  <dt class="dt-key-singleline">Domain</dt>
                  <dd class="dt-value-singleline">{{ convertDomainCodeToName("fqdn", this.$props.domain) }}</dd>

                  <dt class="dt-key-singleline" v-if="userData.accountDisabled === true">Account status</dt>
                  <dd class="dt-value-singleline" v-if="userData.accountDisabled === true">
                    <span class="has-text-weight-bold has-text-danger-dark is-uppercase">Disabled</span>
                  </dd>

                  <dt class="dt-key-singleline">User ID</dt>
                  <dd class="dt-value-singleline">{{userData.userId}}</dd>

                  <dt class="dt-key-singleline">Email</dt>
                  <dd class="dt-value-singleline">{{userData.email}}</dd>

                  <dt class="dt-key-singleline">UPN</dt>
                  <dd class="dt-value-singleline">{{userData.userPrincipalName}}</dd>

                  <dt class="dt-key-singleline">Cloud sync</dt>
                  <dd class="dt-value-singleline" v-if="this.isCloudSynced">Enabled</dd>
                  <dd class="dt-value-singleline" v-else>
                    <span class="has-text-weight-bold has-text-danger-dark is-uppercase">Disabled</span>
                  </dd>

                  <dt class="dt-key-singleline" v-if="this.isCloudSynced">Cloud access</dt>
                  <dd class="dt-value-singleline" v-if="this.isCloudSynced">
                    <ul class="ml-0 mt-0">
                      <li class="inline-list">
                        <a :href="`https://carma-nonprod.mandg.co.uk/#/?searchBy=user&query=${encodeURIComponent(userData.userPrincipalName)}`"
                           target="_blank">Nonprod</a>
                      </li>
                      <li class="inline-list">
                        <a :href="`https://carma.mandg.co.uk/#/?searchBy=user&query=${encodeURIComponent(userData.userPrincipalName)}`"
                           target="_blank">Prod</a>
                      </li>
                    </ul>
                  </dd>

                  <dt class="dt-key-singleline">Extension number</dt>
                  <dd class="dt-value-singleline">{{userData.otherTelephone}}</dd>

                  <dt class="dt-key-singleline">External number</dt>
                  <dd class="dt-value-singleline">{{userData.officeTelephone}}</dd>

                  <dt class="dt-key-singleline" v-if="userData.mobile">Mobile</dt>
                  <dd class="dt-value-singleline" v-if="userData.mobile">{{userData.mobile}}</dd>

                  <dt class="dt-key-singleline">Office</dt>
                  <dd class="dt-value-singleline">{{userData.office}}</dd>

                  <dt class="dt-key-singleline">Job title</dt>
                  <dd class="dt-value-singleline">{{userData.jobTitle}}</dd>

                  <dt class="dt-key-singleline">Department</dt>
                  <dd class="dt-value-singleline">{{userData.department}}</dd>

                  <dt class="dt-key-singleline">Company</dt>
                  <dd class="dt-value-singleline">{{userData.company}}</dd>

                  <dt class="dt-key-singleline">Location</dt>
                  <dd class="dt-value-singleline">{{userData.location}}</dd>

                  <dt class="dt-key-singleline">Manager</dt>
                  <dd class="dt-value-singleline">
                    <a v-bind:href="`#/?domain=${$props.domain}&name=${encodeURIComponent(userData.manager)}`"
                       target="_blank" v-if="userData.manager" >{{userData.manager}}</a>
                  </dd>

                  <dt class="dt-key-singleline">Account created</dt>
                  <dd class="dt-value-singleline">{{userData.dateCreated | vdrPrettyPrintTimestamp}}</dd>

                  <dt class="dt-key-singleline">Account expires</dt>
                  <dd class="dt-value-singleline">{{userData.accountExpires | vdrPrettyPrintTimestamp}}</dd>

                  <dt class="dt-key-singleline">Last logon</dt>
                  <dd class="dt-value-singleline">{{userData.lastLogon | vdrPrettyPrintTimestamp}}</dd>

                  <dt class="dt-key-singleline">Locked out</dt>
                  <dd class="dt-value-singleline">{{userData.lockedOutTime | vdrPrettyPrintTimestamp}}</dd>

                  <dt class="dt-key-singleline" v-if="userData.directReports.length">Direct reports</dt>
                  <dd class="dt-value-multiline" v-if="userData.directReports.length">
                    <ul>
                      <li v-for="directReport in userData.directReports" class="mt-0 is-size-6point5">
                        <a v-bind:href="`#/?domain=${$props.domain}&name=${encodeURIComponent(directReport)}`" target="_blank">{{directReport}}</a>
                      </li>
                    </ul>
                  </dd>

                  <dt class="dt-key-singleline"
                      v-bind:class="{ 'mt-1': userData.directReports.length}"
                      v-if="userData.colleagues.length">Colleagues</dt>
                  <dd class="dt-value-multiline mt-1" v-if="userData.colleagues.length">
                    <ul>
                      <li v-for="colleague in userData.colleagues" class="mt-0 is-size-6point5">
                        <a v-bind:href="`#/?domain=${$props.domain}&name=${encodeURIComponent(colleague)}`" target="_blank">{{colleague}}</a>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>

              <div class="column is-half">
                <div v-if="userData.memberOf.length">
                  <p class="has-text-weight-bold">AD groups ({{ userData.memberOf.length }})
                    <span class="tooltip" @mouseleave="leaveTooltip()" v-if="userData.memberOf.length > 0">
                      <b-tooltip :label="`${this.$data.tooltipText}`" type="is-dark" position="is-right">
                        <i class="mdi mdi-16px mdi-content-copy pl-1"
                           style="cursor: pointer;"
                           @click="copyListToClipboard(userData.memberOf)"></i>
                      </b-tooltip>
                    </span>
                  </p>
                  <input aria-label="Filter groups" v-model="adGroupFilter" class="input is-small mb-2" style="width: 350px" type="text" placeholder="Filter groups">
                  <div class="scrollable">
                    <ul class="memberOfList" v-for="adGroup in filteredAdGroups">
                      <li class="is-size-6point5"><a v-bind:href="`#/?domain=${$props.domain}&name=${encodeURIComponent(adGroup)}`" target="_blank">{{adGroup}}</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </div>
    </template>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/Generic/LoadingSpinner"

export default {
  name: "ModalViewUser",

  components: {
    LoadingSpinner: LoadingSpinner
  },

  data: function () {
    return {
      isLoading: false,
      readyToShow: false,
      userData: {
        memberOf: [] // anything where we access .length should be instantiated as an empty array
      },
      adGroupFilter: '',
      isCloudSynced: false,
      photo: null,
      tooltipText: 'Copy list to clipboard'
    }
  },

  props: {
    firstName: String,
    lastName: String,
    name: String,
    samAccountName: String,
    objectClass: String,
    domain: String
  },

  async mounted() {
    let domain = this.$props.domain
    let formattedDomain = this.vdrConvertDomainCodeToName("netbios", domain);
    let name = this.$props.samAccountName

    this.$appInsights.trackPageView({
      name: "VADER / Home / ModalViewUser",
      pageType: "Modal",
      isLoggedIn: true,
      properties: { name: `${formattedDomain}\\${name}` }
    })


    let timerThreshold = 900
    let timer = setTimeout(() => {
      console.log(`[ModalViewUser][${domain} - ${name}] This is taking longer than usual (${timerThreshold}ms). Activating spinner.`)
      this.isLoading = true
    }, timerThreshold)

    let url = this.$settings.apiBaseUri + `View?domain=${domain}&name=${encodeURIComponent(name)}`
    let httpHeaders = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.$authService.vaderToken}`
      }
    };

    let response = await this.$http.get(url, httpHeaders)
    this.userData = response.data

    let graphUrl = `https://graph.microsoft.com/v1.0/users?$select=displayName,id&$filter=userPrincipalName eq '${encodeURIComponent(this.userData.userPrincipalName)}'`
    let graphHeaders = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${this.$authService.graphToken}`
      }
    }
    this.isCloudSynced = ((await this.$http.get(graphUrl, graphHeaders)).data.value).length !== 0;

    if (this.isCloudSynced) {
      let photoUrl = `https://graph.microsoft.com/beta/users/${encodeURIComponent(this.userData.userPrincipalName)}/photo/$value`
      try {
        let photoBinary = (await this.$http.get(photoUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.$authService.graphToken}`
          },
          responseType: 'arraybuffer',
        })).data
        this.photo = new Buffer(photoBinary, 'binary').toString('base64');
      } catch(error) {
        console.error(`No photo could be loaded for [${this.userData.userPrincipalName}]`, error)
      }
    }

    clearTimeout(timer)
    this.isLoading = false
    this.readyToShow = true

    console.log(this.userData)
  },

  methods: {
    convertDomainCodeToName: function(format, domain) {
      return this.vdrConvertDomainCodeToName(format, domain)
    },

    copyListToClipboard: function(list) {
      this.tooltipText = "Copied"

      let clipboardContent = ""
      list.forEach(listItem => {
        clipboardContent += listItem + "\n"
      })

      navigator.clipboard.writeText(clipboardContent);
    },

    leaveTooltip: function () {
      // Set a small delay to create a more fluid transition between old text and new text
      setTimeout(() => {  this.tooltipText = "Copy list to clipboard" }, 300);
    }
  },

  computed: {
    filteredAdGroups() {
      return this.userData.memberOf.filter(item => {
        return item.toLowerCase().indexOf(this.adGroupFilter.toLowerCase()) > -1
      })
    }
  },

  filters: {
    encoded(value) {
      return encodeURIComponent(value)
    }
  }
}
</script>

<style>
li.inline-list {
  display: inline;
  margin-right: 10px;
}

li.inline-list:not(:last-child):after {
  content: "\B7";
  speak: none;
  margin-left: 10px;
}
</style>