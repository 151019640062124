// Core
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import vueDebounce from 'vue-debounce'

// Styling
import Buefy from 'buefy'
import "./assets/custom.scss"

// Services
import SettingsService from './services/SettingsService'
import AuthService from './services/AuthService'
import VueAppInsights from 'vue-application-insights'

// Helpers
import helpers from './helpers'
Vue.use(helpers)

Vue.use(Buefy)
Vue.use(vueDebounce)
Vue.config.productionTip = false

const main = async () => {
  Vue.prototype.$settings = await (new SettingsService()).getSettings()

  // Vue.observable gives us 'reactivity' over external objects such as updates to the AuthService
  Vue.prototype.$authService = Vue.observable(await (new AuthService(Vue.prototype.$settings)))
  Vue.prototype.$authService.init()

  // Set axios HTTP client (fetch doesn't have all the features of axios yet)
  Vue.prototype.$http = axios.create({})

  // Global interceptor for dealing with token expiry
  const refreshTokenInterceptor = failedRequest => Vue.prototype.$authService.requestNewToken().then(() => {
    if (failedRequest.config.url.includes("graph.microsoft.com")) {
      failedRequest.config.headers['Authorization'] = `Bearer ${(Vue.prototype.$authService.graphToken)}`
    } else if (failedRequest.config.url.includes(Vue.prototype.$settings.apiBaseUri)) {
      failedRequest.config.headers['Authorization'] = `Bearer ${(Vue.prototype.$authService.vaderToken)}`
    }
    return Promise.resolve();
  });
  createAuthRefreshInterceptor(Vue.prototype.$http, refreshTokenInterceptor, {
    statusCodes: [401, 403]
  })

  Vue.use(VueAppInsights, {
    id: Vue.prototype.$settings.appInsightsInstrumentationKey,
    baseName: `VADER`,
    router,
    IConfig: {
      autoTrackPageVisitTime: true,
      enableAutoRouteTracking: true
    }
  })
}

main().then(() => {
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
});