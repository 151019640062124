export default function vdrPrettyPrintTimestamp(value) {
    if (value === null || value === undefined) {
        return ''
    }

    let d = new Date(value)
    let weekday = new Intl.DateTimeFormat('en-gb', { weekday: 'short' }).format(d)
    let day     = new Intl.DateTimeFormat('en-gb', { day: 'numeric' }).format(d)
    let month   = new Intl.DateTimeFormat('en-gb', { month: 'short' }).format(d)
    let year    = new Intl.DateTimeFormat('en-gb', { year: 'numeric' }).format(d)
    let time    = new Intl.DateTimeFormat('en-gb', { hour: 'numeric', minute: 'numeric', second: 'numeric' }).format(d)

    return `${weekday} ${day} ${month} ${year} ${time}`
}