export default function vdrConvertDomainCodeToName(format, domain) {
    if (format.toLowerCase() === "netbios") {
        switch (domain.toUpperCase()) {
            case "PG":
                return "PGDS"
            case "PR":
                return "PRODUCTION"
            case "MG":
                return "MGDOMAIN"
            case "CP":
                return "CORP"
            case "CT":
                return "CORPTEST"
            case "PT":
                return "PRUPIMUAT"
            default:
                return "PGDS"
        }
    }

    if (format.toLowerCase() === "fqdn") {
        switch (domain.toUpperCase()) {
            case "PG":
                return "pgds.local"
            case "PR":
                return "production.local"
            case "MG":
                return "mandg.co.uk"
            case "CP":
                return "corp.local"
            case "CT":
                return "corptest.local"
            case "PT":
                return "prupimuat.internal"
            default:
                return "pgds.local"
        }
    }
}